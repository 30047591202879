import React, {useEffect, useState} from 'react';
import './App.css';
import Routes from "./components/Routes";
import {useDispatch} from "react-redux";
import {getUser} from "./Redux/actions/user.actions";
import axios from "axios";
import {UidContext} from "./components/Context/UidContext";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import CookieConsent from "react-cookie-consent";
import {useNavigate} from "react-router";

const App = () => {

  const [uid, setUid] = useState(null);
  const dispatch = useDispatch();
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
      const fetchToken = async () => {
          await axios({
              method: "get",
              url: `${process.env.REACT_APP_API_URL}/jwtid`,
              withCredentials: true
          })
              .then((res) => {
                  setUid(res.data);
              })
              .catch((err) => {
                  console.log('no token');

              })
      }

      fetchToken();

      const cookies = document.cookie;
      if(cookies.includes('pt=true;')) {
          setAccepted(true);
      }

      if(uid) {
          dispatch(getUser(uid));
      }
  }, [uid])

    return (
        <UidContext.Provider value={uid}>
            <TawkMessengerReact
                propertyId="6277876b7b967b11798e4b79"
                widgetId="1g2hehvm3"/>
            {!accepted &&
                <CookieConsent
                    cookieName="pt"
                    enableDeclineButton="true"
                    buttonText="J'accepte"
                    location="top"
                    declineButtonText="Je refuse"
                    onAccept={() => setAccepted(true)}
                    onDecline={() => setAccepted(false)}
                    expires={365}
                    declineButtonStyle={{
                        background: "#c12a2a",
                        border: "0",
                        borderRadius: "0px",
                        boxShadow: "none",
                        color: "#e5e5e5",
                        cursor: "pointer",
                        flex: "0 0 auto",
                        padding: "5px 10px",
                        margin: "15px",
                    }

                    }

                >
                    Nous stockons vos données personnelles à des fins légales, merci d'accepter ceci pour continuer à vous servir de l'application
                </CookieConsent>
            }

                <Routes accepted={accepted}/>
        </UidContext.Provider>
    );
};

export default App;