import React from "react";
import pt_menu from "../../../assets/images/Accueil/pt/Union.png";
import tc_menu from "../../../assets/images/Accueil/tc/Group21.png";
import pp_menu from "../../../assets/images/Accueil/pp/Group.png";
import vigilance from "../../../assets/images/Accueil/vigilance/Group12.png";
import loto from "../../../assets/images/Accueil/loto/loto.png";
import reglage from "../../../assets/images/Accueil/reglage/roue.png";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../Utils/utils";
import("./Menu.css");

const Menu = () => {
    const userReducer = useSelector((state) => state.userReducer);

    const urlPP = `https://uat.jiconseil.com`;

    const urlElyfec = `https://danone-eaux.elyfec-sps.fr`;

    return (
        <div className="outer-div">
            <div className="inner-div">
                <div className="menu_top">
                    {!isEmpty(userReducer) && JSON.parse(userReducer.acces).accesPT === true && (
                        <div className="pt">
                            <a href="/pt">
                                <div className="pt_menu">
                                    <img src={pt_menu} alt="pt_menu" />
                                </div>
                                <h2>Permis de travaux</h2>
                            </a>
                        </div>
                    )}

                    {/* <div className="tc">
                        <a href="#">
                            <div className="tc_menu">
                                <img src={tc_menu} alt="tc_menu" />
                            </div>
                            <h2>Tournée de chantier</h2>
                        </a>
                    </div> */}

                    {!isEmpty(userReducer) && JSON.parse(userReducer.acces).accesPP === true && (
                        <div className="pp">
                            <a href={urlPP}>
                                <div className="pp_menu">
                                    <img src={pp_menu} alt="tc_menu" />
                                </div>
                                <h2>Plan de prévention</h2>
                            </a>
                        </div>
                    )}

                    <div className="vigilance">
                        <a href={urlElyfec}>
                            <div className="vigilance_menu">
                                <img src={vigilance} alt="tc_menu" />
                            </div>
                            <h2>Obligation de vigilance</h2>
                        </a>
                    </div>
                </div>
                <div className="menu_bot">
                    {/* <div className="loto">
                        <a href="#">
                            <div className="loto_menu">
                                <img src={loto}  alt="tc_menu" />
                            </div>
                            <h2>LOTO</h2>
                        </a>
                    </div> */}

                    <div className="reglageUser">
                        <a href="/reglage">
                            <div className="reglage_user">
                                <img src={reglage} alt="" />
                            </div>
                            <h2>Réglages du compte</h2>
                        </a>
                    </div>

                    {!isEmpty(userReducer) && userReducer.admin ? (
                        <div className="reglageAdmin">
                            <a href="/utilisateurs">
                                <div className="reglage_admin">
                                    <img src={reglage} alt="" />
                                </div>
                                <h2>Réglages Admin</h2>
                            </a>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Menu;
