import React, { useEffect, useState } from "react";
import axios from "axios";
import userReducer from "../../../../../Redux/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { createPT, generateReco, getPt, updateDescPT } from "../../../../../Redux/actions/pt.actions";
import { getUsines } from "../../../../../Redux/actions/usine.actions";
import { isEmpty, sortEntreprisesByRaisonSociale } from "../../../../../Utils/utils";
import contactsReducer from "../../../../../Redux/reducers/contacts.reducer";
import { getContacts } from "../../../../../Redux/actions/contacts.actions";
import { useParams } from "react-router-dom";
import { getEntreprise } from "../../../../../Redux/actions/entreprise.actions";

const CardDesc = ({ pt, preventRefresh, setIsDirty }) => {
    const { id } = useParams();

    const [eu, setEU] = useState({});
    const [ee, setEE] = useState({});
    const [eeCon, setEECon] = useState({});
    const [eeSt, setEESt] = useState({});
    const [eeConSt, setEEConSt] = useState({});
    const [autocompletePrenomSupp, setAutocompletePrenomSupp] = useState([]);
    const [autocompletePrenomSupp2, setAutocompletePrenomSupp2] = useState([]);
    const [autocompletePrenomExploitant, setAutocompletePrenomExploitant] = useState([]);
    const [autocompletePrenomExploitant2, setAutocompletePrenomExploitant2] = useState([]);
    const [autocompleteCDT, setAutocompleteCDT] = useState([]);
    const [autocompleteCDTST, setAutocompleteCDTST] = useState([]);
    const [autocompleteNomEE, setAutocompleteNomEE] = useState([]);
    const [autocompleteNomES, setAutocompleteNomES] = useState([]);
    const [prenomSuppText, setPrenomSuppText] = useState("");
    const [prenomSuppText2, setPrenomSuppText2] = useState("");
    const [prenomExploitantText, setPrenomExploitantText] = useState("");
    const [prenomExploitantText2, setPrenomExploitantText2] = useState("");
    const [nomEEText, setNomEEText] = useState("");
    const [nomESText, setNomESText] = useState("");
    const [prenomCDTText, setPrenomCDTText] = useState("");
    const [prenomCDTSTText, setPrenomCDTSTText] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState({});

    const userReducer = useSelector((state) => state.userReducer);
    const usineReducer = useSelector((state) => state.usineReducer);
    const contactsReducer = useSelector((state) => state.contactsReducer);
    const entrepriseReducer = useSelector((state) => state.entrepriseReducer);

    const dispatch = useDispatch();

    let object = {};

    const [dateDeb, setDateDeb] = useState("");

    function dateDiffInDays(c, d) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const a = new Date(c);
        const b = new Date(d);
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const submit = async () => {
        if (modal3) {
            await handleSetST();
        }

        if (pt !== null) {
            if (data.date_debut_operations_EE !== undefined && data.date_fin_operations_EE !== undefined) {
                if (dateDiffInDays(data.date_debut_operations_EE, data.date_fin_operations_EE) > 7) {
                    alert("La date de fin des opérations est supérieure à 7 jours");
                } else {
                    if (pt.validation_ec === 0) {
                        const datas = {
                            nom_PT: data.nom_PT,
                            description_phase_PT: data.description_phase_PT,
                            id_usine: userReducer.id_hash_usine,
                            entreprise_utilisatrice_PT: {
                                contact_RdP: {
                                    prenomRdP: data.prenomRdP,
                                    nomRdP: data.nomRdP,
                                    telRdP: data.telRdP,
                                    mailRdP: data.mailRdP,
                                },
                                contact_suppleant: {
                                    prenomSuppleant: data.prenomSuppleant,
                                    nomSuppleant: data.nomSuppleant,
                                    telSuppleant: data.telSuppleant,
                                    mailSuppleant: data.mailSuppleant,
                                },
                                contact_suppleant2: {
                                    prenomSuppleant2: data.prenomSuppleant2,
                                    nomSuppleant2: data.nomSuppleant2,
                                    telSuppleant2: data.telSuppleant2,
                                    mailSuppleant2: data.mailSuppleant2,
                                },
                                contact_exploitant: {
                                    prenomExploitant: data.prenomExploitant,
                                    nomExploitant: data.nomExploitant,
                                    telExploitant: data.telExploitant,
                                    mailExploitant: data.mailExploitant,
                                },
                                contact_exploitant2: {
                                    prenomExploitant2: data.prenomExploitant2,
                                    nomExploitant2: data.nomExploitant2,
                                    telExploitant2: data.telExploitant2,
                                    mailExploitant2: data.mailExploitant2,
                                },
                            },
                            entreprise_exterieure_PT: {
                                nom_EE_PT: data.nom_EE_PT,
                                site_EE_PT: data.site_EE_PT,
                                zone_travail_EE: data.zone_travail_EE,
                                ligne_EE: data.ligne_EE,
                                date_vp_EE: data.date_vp_EE,
                                date_debut_operations_EE: data.date_debut_operations_EE,
                                date_fin_operations_EE: data.date_fin_operations_EE,
                            },
                            entreprise_exterieure_PT_contacts: {
                                contact_CdT: {
                                    prenomCdT: data.prenomCdT,
                                    nomCdT: data.nomCdT,
                                    telCdT: data.telCdT,
                                    mailCdT: data.mailCdT,
                                },
                            },
                            entreprise_ST_PT: {
                                nom_ST_PT: data.nom_ST_PT,
                                site_ST_PT: data.site_ST_PT,
                                zone_travail_ST: data.zone_travail_ST,
                                ligne_ST: data.ligne_ST,
                                date_vp_ST: data.date_vp_ST,
                                date_debut_operations_ST: data.date_debut_operations_ST,
                                date_fin_operations_ST: data.date_fin_operations_ST,
                            },
                            entreprise_ST_PT_contacts: {
                                contact_CdT_ST: {
                                    prenomCdT_ST: data.prenomCdT_ST,
                                    nomCdT_ST: data.nomCdT_ST,
                                    telCdT_ST: data.telCdT_ST,
                                    mailCdT_ST: data.mailCdT_ST,
                                },
                            },
                        };

                        setIsDirty(false);
                        await dispatch(updateDescPT(pt.id, datas));
                    } else {
                        alert("Le PT a commencé à être signé, impossible de le modifier");
                    }
                }
            } else {
                const datas = {
                    nom_PT: data.nom_PT,
                    description_phase_PT: data.description_phase_PT,
                    id_usine: userReducer.id_hash_usine,
                    entreprise_utilisatrice_PT: {
                        contact_RdP: {
                            prenomRdP: data.prenomRdP,
                            nomRdP: data.nomRdP,
                            telRdP: data.telRdP,
                            mailRdP: data.mailRdP,
                        },
                        contact_suppleant: {
                            prenomSuppleant: data.prenomSuppleant,
                            nomSuppleant: data.nomSuppleant,
                            telSuppleant: data.telSuppleant,
                            mailSuppleant: data.mailSuppleant,
                        },
                        contact_suppleant2: {
                            prenomSuppleant2: data.prenomSuppleant2,
                            nomSuppleant2: data.nomSuppleant2,
                            telSuppleant2: data.telSuppleant2,
                            mailSuppleant2: data.mailSuppleant2,
                        },
                        contact_exploitant: {
                            prenomExploitant: data.prenomExploitant,
                            nomExploitant: data.nomExploitant,
                            telExploitant: data.telExploitant,
                            mailExploitant: data.mailExploitant,
                        },
                        contact_exploitant2: {
                            prenomExploitant2: data.prenomExploitant2,
                            nomExploitant2: data.nomExploitant2,
                            telExploitant2: data.telExploitant2,
                            mailExploitant2: data.mailExploitant2,
                        },
                    },
                    entreprise_exterieure_PT: {
                        nom_EE_PT: data.nom_EE_PT,
                        site_EE_PT: data.site_EE_PT,
                        zone_travail_EE: data.zone_travail_EE,
                        ligne_EE: data.ligne_EE,
                        date_vp_EE: data.date_vp_EE,
                        date_debut_operations_EE: data.date_debut_operations_EE,
                        date_fin_operations_EE: data.date_fin_operations_EE,
                    },
                    entreprise_exterieure_PT_contacts: {
                        contact_CdT: {
                            prenomCdT: data.prenomCdT,
                            nomCdT: data.nomCdT,
                            telCdT: data.telCdT,
                            mailCdT: data.mailCdT,
                        },
                    },
                    entreprise_ST_PT: {
                        nom_ST_PT: data.nom_ST_PT,
                        site_ST_PT: data.site_ST_PT,
                        zone_travail_ST: data.zone_travail_ST,
                        ligne_ST: data.ligne_ST,
                        date_vp_ST: data.date_vp_ST,
                        date_debut_operations_ST: data.date_debut_operations_ST,
                        date_fin_operations_ST: data.date_fin_operations_ST,
                    },
                    entreprise_ST_PT_contacts: {
                        contact_CdT_ST: {
                            prenomCdT_ST: data.prenomCdT_ST,
                            nomCdT_ST: data.nomCdT_ST,
                            telCdT_ST: data.telCdT_ST,
                            mailCdT_ST: data.mailCdT_ST,
                        },
                    },
                };

                setIsDirty(false);
                await dispatch(updateDescPT(pt.id, datas));
            }
        } else {
            if (data.date_debut_operations_EE !== undefined && data.date_fin_operations_EE !== undefined) {
                if (dateDiffInDays(data.date_debut_operations_EE, data.date_fin_operations_EE) > 7) {
                    alert("La date de fin des opérations est supérieure à 7 jours");
                } else {
                    const datas = {
                        nom_PT: data.nom_PT,
                        description_phase_PT: data.description_phase_PT,
                        id_usine: userReducer.id_hash_usine,
                        entreprise_utilisatrice_PT: {
                            contact_RdP: {
                                prenomRdP: userReducer.prenom,
                                nomRdP: userReducer.nom,
                                telRdP: userReducer.tel,
                                mailRdP: userReducer.mail,
                            },
                            contact_suppleant: {
                                prenomSuppleant: data.prenomSuppleant,
                                nomSuppleant: data.nomSuppleant,
                                telSuppleant: data.telSuppleant,
                                mailSuppleant: data.mailSuppleant,
                            },
                            contact_suppleant2: {
                                prenomSuppleant2: data.prenomSuppleant2,
                                nomSuppleant2: data.nomSuppleant2,
                                telSuppleant2: data.telSuppleant2,
                                mailSuppleant2: data.mailSuppleant2,
                            },
                            contact_exploitant: {
                                prenomExploitant: data.prenomExploitant,
                                nomExploitant: data.nomExploitant,
                                telExploitant: data.telExploitant,
                                mailExploitant: data.mailExploitant,
                            },
                            contact_exploitant2: {
                                prenomExploitant2: data.prenomExploitant2,
                                nomExploitant2: data.nomExploitant2,
                                telExploitant2: data.telExploitant2,
                                mailExploitant2: data.mailExploitant2,
                            },
                        },
                        entreprise_exterieure_PT: {
                            nom_EE_PT: data.nom_EE_PT,
                            site_EE_PT: data.site_EE_PT,
                            zone_travail_EE: data.zone_travail_EE,
                            ligne_EE: data.ligne_EE,
                            date_vp_EE: data.date_vp_EE,
                            date_debut_operations_EE: data.date_debut_operations_EE,
                            date_fin_operations_EE: data.date_fin_operations_EE,
                        },
                        entreprise_exterieure_PT_contacts: {
                            contact_CdT: {
                                prenomCdT: data.prenomCdT,
                                nomCdT: data.nomCdT,
                                telCdT: data.telCdT,
                                mailCdT: data.mailCdT,
                            },
                        },
                        entreprise_ST_PT: {
                            nom_ST_PT: data.nom_ST_PT,
                            site_ST_PT: data.site_ST_PT,
                            zone_travail_ST: data.zone_travail_ST,
                            ligne_ST: data.ligne_ST,
                            date_vp_ST: data.date_vp_ST,
                            date_debut_operations_ST: data.date_debut_operations_ST,
                            date_fin_operations_ST: data.date_fin_operations_ST,
                        },
                        entreprise_ST_PT_contacts: {
                            contact_CdT_ST: {
                                prenomCdT_ST: data.prenomCdT_ST,
                                nomCdT_ST: data.nomCdT_ST,
                                telCdT_ST: data.telCdT_ST,
                                mailCdT_ST: data.mailCdT_ST,
                            },
                        },
                    };

                    setIsDirty(false);

                    dispatch(createPT(datas));
                }
            } else {
                const datas = {
                    nom_PT: data.nom_PT,
                    description_phase_PT: data.description_phase_PT,
                    id_usine: userReducer.id_hash_usine,
                    entreprise_utilisatrice_PT: {
                        contact_RdP: {
                            prenomRdP: userReducer.prenom,
                            nomRdP: userReducer.nom,
                            telRdP: userReducer.tel,
                            mailRdP: userReducer.mail,
                        },
                        contact_suppleant: {
                            prenomSuppleant: data.prenomSuppleant,
                            nomSuppleant: data.nomSuppleant,
                            telSuppleant: data.telSuppleant,
                            mailSuppleant: data.mailSuppleant,
                        },
                        contact_suppleant2: {
                            prenomSuppleant2: data.prenomSuppleant2,
                            nomSuppleant2: data.nomSuppleant2,
                            telSuppleant2: data.telSuppleant2,
                            mailSuppleant2: data.mailSuppleant2,
                        },
                        contact_exploitant: {
                            prenomExploitant: data.prenomExploitant,
                            nomExploitant: data.nomExploitant,
                            telExploitant: data.telExploitant,
                            mailExploitant: data.mailExploitant,
                        },
                        contact_exploitant2: {
                            prenomExploitant2: data.prenomExploitant2,
                            nomExploitant2: data.nomExploitant2,
                            telExploitant2: data.telExploitant2,
                            mailExploitant2: data.mailExploitant2,
                        },
                    },
                    entreprise_exterieure_PT: {
                        nom_EE_PT: data.nom_EE_PT,
                        site_EE_PT: data.site_EE_PT,
                        zone_travail_EE: data.zone_travail_EE,
                        ligne_EE: data.ligne_EE,
                        date_vp_EE: data.date_vp_EE,
                        date_debut_operations_EE: data.date_debut_operations_EE,
                        date_fin_operations_EE: data.date_fin_operations_EE,
                    },
                    entreprise_exterieure_PT_contacts: {
                        contact_CdT: {
                            prenomCdT: data.prenomCdT,
                            nomCdT: data.nomCdT,
                            telCdT: data.telCdT,
                            mailCdT: data.mailCdT,
                        },
                    },
                    entreprise_ST_PT: {
                        nom_ST_PT: data.nom_ST_PT,
                        site_ST_PT: data.site_ST_PT,
                        zone_travail_ST: data.zone_travail_ST,
                        ligne_ST: data.ligne_ST,
                        date_vp_ST: data.date_vp_ST,
                        date_debut_operations_ST: data.date_debut_operations_ST,
                        date_fin_operations_ST: data.date_fin_operations_ST,
                    },
                    entreprise_ST_PT_contacts: {
                        contact_CdT_ST: {
                            prenomCdT_ST: data.prenomCdT_ST,
                            nomCdT_ST: data.nomCdT_ST,
                            telCdT_ST: data.telCdT_ST,
                            mailCdT_ST: data.mailCdT_ST,
                        },
                    },
                };

                setIsDirty(false);

                await dispatch(createPT(datas));
            }
        }
    };

    const onChangeHandler = (id) => {
        let matches = [];
        if (id === "prenomSuppleant") {
            matches = contactsReducer.filter((user) => {
                const regex = new RegExp(`^${prenomSuppText}`, "gi");
                if (user.prenom.match(regex) && user.id_hash_usine === userReducer.id_hash_usine) {
                    return user;
                }
            });
            setAutocompletePrenomSupp(matches);
        } else if (id === "prenomSuppleant2") {
            matches = contactsReducer.filter((user) => {
                const regex = new RegExp(`^${prenomSuppText2}`, "gi");
                if (user.prenom.match(regex) && user.id_hash_usine === userReducer.id_hash_usine) {
                    return user;
                }
            });
            setAutocompletePrenomSupp2(matches);
        } else if (id === "prenomExploitant") {
            matches = contactsReducer.filter((user) => {
                const regex = new RegExp(`^${prenomExploitantText}`, "gi");
                if (user.prenom.match(regex) && user.id_hash_usine === userReducer.id_hash_usine) {
                    return user;
                }
            });
            setAutocompletePrenomExploitant(matches);
        } else if (id === "prenomExploitant2") {
            matches = contactsReducer.filter((user) => {
                const regex = new RegExp(`^${prenomExploitantText2}`, "gi");
                if (user.prenom.match(regex) && user.id_hash_usine === userReducer.id_hash_usine) {
                    return user;
                }
            });
            setAutocompletePrenomExploitant2(matches);
        } else if (id === "nom_EE_PT") {
            matches = entrepriseReducer
                .filter((entreprise) => {
                    const regex = new RegExp(`^${nomEEText}`, "gi");
                    if (entreprise.raison_sociale.match(regex)) {
                        return entreprise;
                    }
                })
                .sort(sortEntreprisesByRaisonSociale);
            setAutocompleteNomEE(matches);
        } else if (id === "nom_ST_PT") {
            matches = entrepriseReducer
                .filter((entreprise) => {
                    const regex = new RegExp(`^${nomESText}`, "gi");
                    if (entreprise.raison_sociale.match(regex)) {
                        return entreprise;
                    }
                })
                .sort(sortEntreprisesByRaisonSociale);
            setAutocompleteNomES(matches);
        } else if (id === "prenomCdT") {
            let id_entre = undefined;
            entrepriseReducer.map((entreprise) => {
                if (entreprise.raison_sociale === data.nom_EE_PT) {
                    id_entre = entreprise.id_hash;
                }
            });

            matches = contactsReducer.filter((user) => {
                const regex = new RegExp(`^${prenomCDTText}`, "gi");
                if (user.prenom.match(regex) && user.id_entreprise === id_entre) {
                    return user;
                }
            });
            setAutocompleteCDT(matches);
        } else if (id === "prenomCdT_ST") {
            let id_entre = undefined;
            entrepriseReducer.map((entreprise) => {
                if (entreprise.raison_sociale === data.nom_ST_PT) {
                    id_entre = entreprise.id_hash;
                }
            });

            matches = contactsReducer.filter((user) => {
                const regex = new RegExp(`^${prenomCDTSTText}`, "gi");
                if (user.prenom.match(regex) && user.id_entreprise === id_entre) {
                    return user;
                }
            });
            setAutocompleteCDTST(matches);
        }
    };

    function handle(e) {
        setIsDirty(true);
        if (e.target.id === "prenomSuppleant") {
            setPrenomSuppText(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "prenomSuppleant2") {
            setPrenomSuppText2(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "prenomExploitant") {
            setPrenomExploitantText(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "prenomExploitant2") {
            setPrenomExploitantText2(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "nom_EE_PT") {
            setNomEEText(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "nom_ST_PT") {
            setNomESText(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "prenomCdT") {
            setPrenomCDTText(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else if (e.target.id === "prenomCdT_ST") {
            setPrenomCDTSTText(e.target.value);
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
            onChangeHandler(e.target.id);
        } else {
            const newdata = { ...data };
            newdata[e.target.id] = e.target.value;
            setData(newdata);
        }
    }

    const handleSelectedPrenomSupp = (contact) => {
        const newdata = { ...data };
        document.getElementById("prenomSuppleant").value = contact.prenom;
        newdata.prenomSuppleant = contact.prenom;
        document.getElementById("nomSuppleant").value = contact.nom;
        newdata.nomSuppleant = contact.nom;
        document.getElementById("telSuppleant").value = contact.tel || contact.mobile;
        newdata.telSuppleant = contact.tel || contact.mobile;
        document.getElementById("mailSuppleant").value = contact.mail;
        newdata.mailSuppleant = contact.mail;

        setData(newdata);

        setAutocompletePrenomSupp([]);
    };

    const handleSelectedPrenomSupp2 = (contact) => {
        const newdata = { ...data };
        document.getElementById("prenomSuppleant2").value = contact.prenom;
        newdata.prenomSuppleant2 = contact.prenom;
        document.getElementById("nomSuppleant2").value = contact.nom;
        newdata.nomSuppleant2 = contact.nom;
        document.getElementById("telSuppleant2").value = contact.tel || contact.mobile;
        newdata.telSuppleant2 = contact.tel || contact.mobile;
        document.getElementById("mailSuppleant2").value = contact.mail;
        newdata.mailSuppleant2 = contact.mail;

        setData(newdata);

        setAutocompletePrenomSupp2([]);
    };

    const handleSelectedPrenomExploitant = (contact) => {
        const newdata = { ...data };
        document.getElementById("prenomExploitant").value = contact.prenom;
        newdata.prenomExploitant = contact.prenom;
        document.getElementById("nomExploitant").value = contact.nom;
        newdata.nomExploitant = contact.nom;
        document.getElementById("telExploitant").value = contact.tel || contact.mobile;
        newdata.telExploitant = contact.tel || contact.mobile;
        document.getElementById("mailExploitant").value = contact.mail;
        newdata.mailExploitant = contact.mail;

        setData(newdata);

        setAutocompletePrenomExploitant([]);
    };

    const handleSelectedPrenomExploitant2 = (contact) => {
        const newdata = { ...data };
        document.getElementById("prenomExploitant2").value = contact.prenom;
        newdata.prenomExploitant2 = contact.prenom;
        document.getElementById("nomExploitant2").value = contact.nom;
        newdata.nomExploitant2 = contact.nom;
        document.getElementById("telExploitant2").value = contact.tel || contact.mobile;
        newdata.telExploitant2 = contact.tel || contact.mobile;
        document.getElementById("mailExploitant2").value = contact.mail;
        newdata.mailExploitant2 = contact.mail;

        setData(newdata);

        setAutocompletePrenomExploitant2([]);
    };

    const handleSelectedNomEE = (contact) => {
        const newdata = { ...data };
        document.getElementById("nom_EE_PT").value = contact.raison_sociale;
        newdata.nom_EE_PT = contact.raison_sociale;

        setData(newdata);

        setAutocompleteNomEE([]);
    };

    const handleSelectedNomES = (contact) => {
        const newdata = { ...data };
        document.getElementById("nom_ST_PT").value = contact.raison_sociale;
        newdata.nom_ST_PT = contact.raison_sociale;

        setData(newdata);

        setAutocompleteNomES([]);
    };

    const handleSelectedCDT = (contact) => {
        const newdata = { ...data };
        document.getElementById("prenomCdT").value = contact.prenom;
        newdata.prenomCdT = contact.prenom;
        document.getElementById("nomCdT").value = contact.nom;
        newdata.nomCdT = contact.nom;
        document.getElementById("telCdT").value = contact.tel || contact.mobile;
        newdata.telCdT = contact.tel || contact.mobile;
        document.getElementById("mailCdT").value = contact.mail;
        newdata.mailCdT = contact.mail;

        setData(newdata);

        setAutocompleteCDT([]);
    };

    const handleSelectedCDTST = (contact) => {
        const newdata = { ...data };
        document.getElementById("prenomCdT_ST").value = contact.prenom;
        newdata.prenomCdT_ST = contact.prenom;
        document.getElementById("nomCdT_ST").value = contact.nom;
        newdata.nomCdT_ST = contact.nom;
        document.getElementById("telCdT_ST").value = contact.tel || contact.mobile;
        newdata.telCdT_ST = contact.tel || contact.mobile;
        document.getElementById("mailCdT_ST").value = contact.mail;
        newdata.mailCdT_ST = contact.mail;

        setData(newdata);

        setAutocompleteCDTST([]);
    };

    function handleDate(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
        setIsDirty(true);
    }

    const handleClick = () => {
        toggleModalAddUser();
    };
    const handleClick2 = () => {
        toggleModalAddExploitant();
    };
    const handleClick3 = () => {
        toggleModalAddEST();
    };

    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);

    const [deletedModalValues, setDeletedModalValues] = useState(false);
    const [deletedModal2Values, setDeletedModal2Values] = useState(false);
    const [deletedModal3Values, setDeletedModal3Values] = useState(false);

    const toggleModalAddUser = () => {
        if (modal) {
            object = data;
            delete object["prenomSuppleant2"];
            delete object["nomSuppleant2"];
            delete object["telSuppleant2"];
            delete object["mailSuppleant2"];
            setData(object);
            setDeletedModalValues(true);
            setModal(!modal);
        } else {
            setModal(!modal);
        }
    };

    const toggleModalAddExploitant = () => {
        if (modal2) {
            object = data;
            delete object["prenomExploitant2"];
            delete object["nomExploitant2"];
            delete object["telExploitant2"];
            delete object["mailExploitant2"];
            setData(object);
            setDeletedModal2Values(true);
            setModal2(!modal2);
        } else {
            setModal2(!modal2);
        }
    };
    const toggleModalAddEST = () => {
        if (modal3) {
            object = data;
            delete object["nom_ST_PT"];
            delete object["site_ST_PT"];
            delete object["zone_travail_ST"];
            delete object["ligne_ST"];
            delete object["date_vp_ST"];
            delete object["date_debut_operations_ST"];
            delete object["date_fin_operations_ST"];
            delete object["prenomCdT_ST"];
            delete object["nomCdT_ST"];
            delete object["telCdT_ST"];
            delete object["mailCdT_ST"];
            setData(object);
            setDeletedModal3Values(true);
            setModal3(false);
        } else {
            setModal3(true);
            setDeletedModal3Values(false);
        }
    };

    const handleSetST = () => {
        if (document.getElementById("site_ST_PT").value !== null) {
            const newdata = data;
            newdata.site_ST_PT = document.getElementById("site_ST_PT").value;
            setData(newdata);
        }
        if (document.getElementById("zone_travail_ST").value !== null) {
            const newdata = data;
            newdata.zone_travail_ST = document.getElementById("zone_travail_ST").value;
            setData(newdata);
        }
        if (document.getElementById("ligne_ST").value !== null) {
            const newdata = data;
            newdata.ligne_ST = document.getElementById("ligne_ST").value;
            setData(newdata);
        }
        if (document.getElementById("date_vp_ST").value !== null) {
            const newdata = data;
            newdata.date_vp_ST = document.getElementById("date_vp_ST").value;
            setData(newdata);
        }
        if (document.getElementById("date_debut_operations_ST").value !== null) {
            const newdata = data;
            newdata.date_debut_operations_ST = document.getElementById("date_debut_operations_ST").value;
            setData(newdata);
        }

        if (document.getElementById("date_fin_operations_ST").value !== null) {
            const newdata = data;
            newdata.date_fin_operations_ST = document.getElementById("date_fin_operations_ST").value;
            setData(newdata);
        }
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    //Met les données préremplies dans data pour la persistance des données
    const handleSetData = async () => {
        if (isEmpty(usineReducer[0])) {
            await dispatch(getUsines());
        }

        //nomPt descriptionPt
        if (pt.nom_PT) {
            handleData("nom_PT", pt.nom_PT);
        }

        if (pt.description_phase_PT) {
            handleData("description_phase_PT", pt.description_phase_PT);
        }

        //contactRDP
        if (eu.contact_RdP) {
            handleData("prenomRdP", eu.contact_RdP.prenomRdP);
        }
        if (eu.contact_RdP.nomRdP) {
            handleData("nomRdP", eu.contact_RdP.nomRdP);
        }
        if (eu.contact_RdP.telRdP) {
            handleData("telRdP", eu.contact_RdP.telRdP);
        }
        if (eu.contact_RdP.mailRdP) {
            handleData("mailRdP", eu.contact_RdP.mailRdP);
        }

        //contactSuppleant
        if (eu.contact_suppleant.prenomSuppleant) {
            handleData("prenomSuppleant", eu.contact_suppleant.prenomSuppleant);
        }
        if (eu.contact_suppleant.nomSuppleant) {
            handleData("nomSuppleant", eu.contact_suppleant.nomSuppleant);
        }
        if (eu.contact_suppleant.telSuppleant) {
            handleData("telSuppleant", eu.contact_suppleant.telSuppleant);
        }
        if (eu.contact_suppleant.mailSuppleant) {
            handleData("mailSuppleant", eu.contact_suppleant.mailSuppleant);
        }

        //contactSuppleant2
        if (eu.contact_suppleant2.prenomSuppleant2) {
            handleData("prenomSuppleant2", eu.contact_suppleant2.prenomSuppleant2);
        }
        if (eu.contact_suppleant2.nomSuppleant2) {
            handleData("nomSuppleant2", eu.contact_suppleant2.nomSuppleant2);
        }
        if (eu.contact_suppleant2.telSuppleant2) {
            handleData("telSuppleant2", eu.contact_suppleant2.telSuppleant2);
        }
        if (eu.contact_suppleant2.mailSuppleant2) {
            handleData("mailSuppleant2", eu.contact_suppleant2.mailSuppleant2);
        }

        //contactExploitant
        if (eu.contact_exploitant.prenomExploitant) {
            handleData("prenomExploitant", eu.contact_exploitant.prenomExploitant);
        }
        if (eu.contact_exploitant.nomExploitant) {
            handleData("nomExploitant", eu.contact_exploitant.nomExploitant);
        }
        if (eu.contact_exploitant.telExploitant) {
            handleData("telExploitant", eu.contact_exploitant.telExploitant);
        }
        if (eu.contact_exploitant.mailExploitant) {
            handleData("mailExploitant", eu.contact_exploitant.mailExploitant);
        }

        //contactExploitant2
        if (eu.contact_exploitant2.prenomExploitant2) {
            handleData("prenomExploitant2", eu.contact_exploitant2.prenomExploitant2);
        }
        if (eu.contact_exploitant2.nomExploitant2) {
            handleData("nomExploitant2", eu.contact_exploitant2.nomExploitant2);
        }
        if (eu.contact_exploitant2.telExploitant2) {
            handleData("telExploitant2", eu.contact_exploitant2.telExploitant2);
        }
        if (eu.contact_exploitant2.mailExploitant2) {
            handleData("mailExploitant2", eu.contact_exploitant2.mailExploitant2);
        }

        //entreprise extérieure
        if (ee.nom_EE_PT) {
            handleData("nom_EE_PT", ee.nom_EE_PT);
        }
        if (ee.site_EE_PT) {
            handleData("site_EE_PT", ee.site_EE_PT);
        }
        if (ee.zone_travail_EE) {
            handleData("zone_travail_EE", ee.zone_travail_EE);
        }
        if (ee.ligne_EE) {
            handleData("ligne_EE", ee.ligne_EE);
        }
        if (ee.date_vp_EE) {
            handleData("date_vp_EE", ee.date_vp_EE);
        }
        if (ee.date_debut_operations_EE) {
            handleData("date_debut_operations_EE", ee.date_debut_operations_EE);
        }
        if (ee.date_fin_operations_EE) {
            handleData("date_fin_operations_EE", ee.date_fin_operations_EE);
        }

        //entreprise extérieure contact
        if (eeCon.contact_CdT.prenomCdT) {
            handleData("prenomCdT", eeCon.contact_CdT.prenomCdT);
        }
        if (eeCon.contact_CdT.nomCdT) {
            handleData("nomCdT", eeCon.contact_CdT.nomCdT);
        }
        if (eeCon.contact_CdT.telCdT) {
            handleData("telCdT", eeCon.contact_CdT.telCdT);
        }
        if (eeCon.contact_CdT.mailCdT) {
            handleData("mailCdT", eeCon.contact_CdT.mailCdT);
        }

        //entreprise sous traitante
        if (eeSt.nom_ST_PT) {
            handleData("nom_ST_PT", eeSt.nom_ST_PT);
        }
        if (eeSt.site_ST_PT) {
            handleData("site_ST_PT", eeSt.site_ST_PT);
        }
        if (eeSt.zone_travail_ST) {
            handleData("zone_travail_ST", eeSt.zone_travail_ST);
        }
        if (eeSt.ligne_ST) {
            handleData("ligne_ST", eeSt.ligne_ST);
        }
        if (eeSt.date_vp_ST) {
            handleData("date_vp_ST", eeSt.date_vp_ST);
        }
        if (eeSt.date_debut_operations_ST) {
            handleData("date_debut_operations_ST", eeSt.date_debut_operations_ST);
        }
        if (eeSt.date_fin_operations_ST) {
            handleData("date_fin_operations_ST", eeSt.date_fin_operations_ST);
        }

        //entreprise sous traitante contact
        if (eeConSt.contact_CdT_ST.prenomCdT_ST) {
            handleData("prenomCdT_ST", eeConSt.contact_CdT_ST.prenomCdT_ST);
        }
        if (eeConSt.contact_CdT_ST.nomCdT_ST) {
            handleData("nomCdT_ST", eeConSt.contact_CdT_ST.nomCdT_ST);
        }
        if (eeConSt.contact_CdT_ST.telCdT_ST) {
            handleData("telCdT_ST", eeConSt.contact_CdT_ST.telCdT_ST);
        }
        if (eeConSt.contact_CdT_ST.mailCdT_ST) {
            handleData("mailCdT_ST", eeConSt.contact_CdT_ST.mailCdT_ST);
        }

        setData(object);

        if (
            eu.contact_suppleant2.prenomSuppleant2 !== undefined ||
            eu.contact_suppleant2.nomSuppleant2 !== undefined ||
            eu.contact_suppleant2.telSuppleant2 !== undefined ||
            eu.contact_suppleant2.mailSuppleant2 !== undefined
        ) {
            setModal(true);
        }
        if (
            eu.contact_exploitant2.prenomExploitant2 !== undefined ||
            eu.contact_exploitant2.nomExploitant2 !== undefined ||
            eu.contact_exploitant2.telExploitant2 !== undefined ||
            eu.contact_exploitant2.mailExploitant2 !== undefined
        ) {
            setModal2(true);
        }

        if (
            eeSt.nom_ST_PT !== undefined ||
            eeSt.site_ST_PT !== undefined ||
            eeSt.zone_travail_ST !== undefined ||
            eeSt.ligne_ST !== undefined ||
            eeSt.date_vp_ST !== undefined ||
            eeSt.date_debut_operations_ST !== undefined ||
            eeSt.date_fin_operations_ST !== undefined ||
            eeConSt.contact_CdT_ST.prenomCdT_ST ||
            eeConSt.contact_CdT_ST.nomCdT_ST ||
            eeConSt.contact_CdT_ST.telCdT_ST ||
            eeConSt.contact_CdT_ST.mailCdT_ST
        ) {
            setModal3(true);
        }
    };

    const handleData = (id, value) => {
        object[id] = value;
    };

    useEffect(() => {
        if (pt !== null) {
            dispatch(getContacts());
            dispatch(getEntreprise());
            const ptEU = JSON.parse(pt.entreprise_utilisatrice_PT);
            setEU(ptEU);
            const ptEE = JSON.parse(pt.entreprise_exterieure_PT);
            setEE(ptEE);
            const ptEECon = JSON.parse(pt.entreprise_exterieure_PT_contacts);
            setEECon(ptEECon);
            const ptEESt = JSON.parse(pt.entreprise_ST_PT);
            setEESt(ptEESt);
            const ptEEConSt = JSON.parse(pt.entreprise_ST_PT_contacts);
            setEEConSt(ptEEConSt);
            if (isLoading) {
                setIsLoading(false);
            }
            handleSetData();
        } else {
            dispatch(getContacts());
            dispatch(getEntreprise());
            dispatch(getUsines());
            setIsLoading(false);
        }
    }, [isLoading, pt, userReducer]);

    return (
        <div>
            {!isLoading && (
                <form onSubmit={preventRefresh}>
                    <div>
                        <div className="name_newpt">NOM DU PERMIS</div>
                        <div className="content_descnewpt_input">
                            <input
                                placeholder="Exemple : Démontage tuyauterie ovoide"
                                onChange={(e) => handle(e)}
                                id="nom_PT"
                                type="text"
                                defaultValue={pt ? pt.nom_PT : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            ></input>
                        </div>
                    </div>
                    <div>
                        <div className="name_newpt" style={{ marginBottom: "20px" }}>
                            RÉDACTEUR DU PERMIS
                        </div>
                        <div style={{ color: "grey" }}>
                            <p style={{ marginBottom: "25px" }}>{pt ? eu.contact_RdP.prenomRdP : userReducer.prenom}</p>
                            <p style={{ marginBottom: "25px" }}>{pt ? eu.contact_RdP.nomRdP : userReducer.nom}</p>
                            <p style={{ marginBottom: "25px" }}>{pt ? eu.contact_RdP.telRdP : userReducer.tel}</p>
                            <p style={{ marginBottom: "25px" }}>{pt ? eu.contact_RdP.mailRdP : userReducer.mail}</p>
                        </div>
                    </div>
                    <div>
                        <div className="name_newpt">DONNEUR D'ORDRE SUPPLÉANT N°1</div>
                        <div className="content_descnewpt_input">
                            <input
                                placeholder="Prénom"
                                onBlur={() => {
                                    setTimeout(() => {
                                        setAutocompletePrenomSupp([]);
                                    }, 100);
                                }}
                                onChange={(e) => handle(e)}
                                id="prenomSuppleant"
                                type="text"
                                defaultValue={pt ? eu.contact_suppleant.prenomSuppleant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                                autoComplete="off"
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    backgroundColor: "white",
                                    width: "25%",
                                    marginTop: "60px",
                                }}
                            >
                                {autocompletePrenomSupp &&
                                    autocompletePrenomSupp.map((suggest, i) => (
                                        <div
                                            key={i}
                                            className="suggest"
                                            onClick={() => handleSelectedPrenomSupp(suggest)}
                                        >
                                            {suggest.prenom} {suggest.nom}
                                        </div>
                                    ))}
                            </div>
                            <input
                                placeholder="Nom"
                                onChange={(e) => handle(e)}
                                id="nomSuppleant"
                                type="text"
                                defaultValue={pt ? eu.contact_suppleant.nomSuppleant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                            <input
                                placeholder="Téléphone"
                                onChange={(e) => handle(e)}
                                id="telSuppleant"
                                type="text"
                                defaultValue={pt ? eu.contact_suppleant.telSuppleant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                            <input
                                placeholder="Email"
                                onChange={(e) => handle(e)}
                                id="mailSuppleant"
                                type="text"
                                defaultValue={pt ? eu.contact_suppleant.mailSuppleant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>

                        <div className="button_add_do">
                            <button onClick={handleClick} className="button_add_do">
                                {!modal ? <>Ajouter</> : <>Supprimer</>} un donneur d'ordre suppléant
                            </button>
                            {modal ? (
                                <div>
                                    <div className="name_newpt">DONNEUR D'ORDRE SUPPLÉANT N°2</div>
                                    <div className="content_descnewpt_input">
                                        <input
                                            placeholder="Prénom"
                                            onChange={(e) => handle(e)}
                                            id="prenomSuppleant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModalValues
                                                    ? pt
                                                        ? eu.contact_suppleant2.prenomSuppleant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                            autoComplete="off"
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setAutocompletePrenomSupp2([]);
                                                }, 100);
                                            }}
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "white",
                                                width: "25%",
                                                marginTop: "60px",
                                            }}
                                        >
                                            {autocompletePrenomSupp2 &&
                                                autocompletePrenomSupp2.map((suggest, i) => (
                                                    <div
                                                        key={i}
                                                        className="suggest"
                                                        onClick={() => handleSelectedPrenomSupp2(suggest)}
                                                    >
                                                        {suggest.prenom} {suggest.nom}
                                                    </div>
                                                ))}
                                        </div>
                                        <input
                                            placeholder="Nom"
                                            onChange={(e) => handle(e)}
                                            id="nomSuppleant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModalValues
                                                    ? pt
                                                        ? eu.contact_suppleant2.nomSuppleant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <input
                                            placeholder="Téléphone"
                                            onChange={(e) => handle(e)}
                                            id="telSuppleant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModalValues
                                                    ? pt
                                                        ? eu.contact_suppleant2.telSuppleant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <input
                                            placeholder="Email"
                                            onChange={(e) => handle(e)}
                                            id="mailSuppleant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModalValues
                                                    ? pt
                                                        ? eu.contact_suppleant2.mailSuppleant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="border_grey_newpt">
                        <div className="name_newpt">EXPLOITANT N°1</div>
                        <div className="content_descnewpt_input">
                            <input
                                placeholder="Prénom"
                                onChange={(e) => handle(e)}
                                id="prenomExploitant"
                                type="text"
                                defaultValue={pt ? eu.contact_exploitant.prenomExploitant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                                autoComplete="off"
                                onBlur={() => {
                                    setTimeout(() => {
                                        setAutocompletePrenomExploitant([]);
                                    }, 100);
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    backgroundColor: "white",
                                    width: "25%",
                                    marginTop: "60px",
                                }}
                            >
                                {autocompletePrenomExploitant &&
                                    autocompletePrenomExploitant.map((suggest, i) => (
                                        <div
                                            key={i}
                                            className="suggest"
                                            onClick={() => handleSelectedPrenomExploitant(suggest)}
                                        >
                                            {suggest.prenom} {suggest.nom}
                                        </div>
                                    ))}
                            </div>
                            <input
                                placeholder="Nom"
                                onChange={(e) => handle(e)}
                                id="nomExploitant"
                                type="text"
                                defaultValue={pt ? eu.contact_exploitant.nomExploitant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                            <input
                                placeholder="Téléphone"
                                onChange={(e) => handle(e)}
                                id="telExploitant"
                                type="text"
                                defaultValue={pt ? eu.contact_exploitant.telExploitant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                            <input
                                placeholder="Email"
                                onChange={(e) => handle(e)}
                                id="mailExploitant"
                                type="text"
                                defaultValue={pt ? eu.contact_exploitant.mailExploitant : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                        <div className="button_add_do">
                            <button onClick={handleClick2} className="button_add_do">
                                {!modal2 ? <>Ajouter</> : <>Supprimer</>} un exploitant
                            </button>

                            {modal2 && (
                                <div className="new_exploitant" style={{ marginBottom: "20px" }}>
                                    <div className="name_newpt">EXPLOITANT N°2</div>
                                    <div className="content_descnewpt_input">
                                        <input
                                            placeholder="Prénom"
                                            onChange={(e) => handle(e)}
                                            id="prenomExploitant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModal2Values
                                                    ? pt
                                                        ? eu.contact_exploitant2.prenomExploitant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                            autoComplete="off"
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setAutocompletePrenomExploitant2([]);
                                                }, 100);
                                            }}
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "white",
                                                width: "25%",
                                                marginTop: "60px",
                                            }}
                                        >
                                            {autocompletePrenomExploitant2 &&
                                                autocompletePrenomExploitant2.map((suggest, i) => (
                                                    <div
                                                        key={i}
                                                        className="suggest"
                                                        onClick={() => handleSelectedPrenomExploitant2(suggest)}
                                                    >
                                                        {suggest.prenom} {suggest.nom}
                                                    </div>
                                                ))}
                                        </div>
                                        <input
                                            placeholder="Nom"
                                            onChange={(e) => handle(e)}
                                            id="nomExploitant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModal2Values
                                                    ? pt
                                                        ? eu.contact_exploitant2.nomExploitant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <input
                                            placeholder="Téléphone"
                                            onChange={(e) => handle(e)}
                                            id="telExploitant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModal2Values
                                                    ? pt
                                                        ? eu.contact_exploitant2.telExploitant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <input
                                            placeholder="Email"
                                            onChange={(e) => handle(e)}
                                            id="mailExploitant2"
                                            type="text"
                                            defaultValue={
                                                !deletedModal2Values
                                                    ? pt
                                                        ? eu.contact_exploitant2.mailExploitant2
                                                        : ""
                                                    : ""
                                            }
                                            disabled={
                                                id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="name_newpt">ENTREPRISE EXTERIEURE</div>
                        <div className="content_descnewpt_input">
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Nom de l'EE : </label>
                                <input
                                    type="text"
                                    onChange={(e) => handle(e)}
                                    id="nom_EE_PT"
                                    placeholder="Nom de l'entreprise"
                                    defaultValue={pt ? ee.nom_EE_PT : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                    autoComplete="off"
                                    onBlur={() => {
                                        setTimeout(() => {
                                            setAutocompleteNomEE([]);
                                        }, 100);
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    backgroundColor: "white",
                                    width: "25%",
                                    marginTop: "60px",
                                    marginLeft: "14.75%",
                                }}
                            >
                                {autocompleteNomEE &&
                                    autocompleteNomEE.map((suggest, i) => (
                                        <div key={i} className="suggest" onClick={() => handleSelectedNomEE(suggest)}>
                                            {suggest.raison_sociale}
                                        </div>
                                    ))}
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Site : </label>
                                <input
                                    type="text"
                                    onChange={(e) => handle(e)}
                                    id="site_EE_PT"
                                    placeholder="Site"
                                    defaultValue={pt ? ee.site_EE_PT : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Atelier : </label>
                                <input
                                    type="text"
                                    onChange={(e) => handle(e)}
                                    id="zone_travail_EE"
                                    placeholder="Atelier"
                                    defaultValue={pt ? ee.zone_travail_EE : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Ligne : </label>
                                <input
                                    type="text"
                                    onChange={(e) => handle(e)}
                                    id="ligne_EE"
                                    placeholder="Ligne "
                                    defaultValue={pt ? ee.ligne_EE : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Date de la PV/PDT : </label>
                                <input
                                    type="date"
                                    onChange={(e) => handleDate(e)}
                                    id="date_vp_EE"
                                    placeholder="Date"
                                    defaultValue={pt ? ee.date_vp_EE : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Début opérations : </label>
                                <input
                                    type="date"
                                    onChange={(e) => handleDate(e)}
                                    id="date_debut_operations_EE"
                                    placeholder="Début des opérations"
                                    defaultValue={pt ? ee.date_debut_operations_EE : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <label style={{ width: "70%" }}>Fin opérations : </label>
                                <input
                                    type="date"
                                    onChange={(e) => handleDate(e)}
                                    id="date_fin_operations_EE"
                                    placeholder="Fin des opérations"
                                    defaultValue={pt ? ee.date_fin_operations_EE : ""}
                                    disabled={
                                        id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border_grey_newpt">
                        <div className="name_newpt">EXÉCUTANT CHARGÉ DES TRAVAUX EE</div>
                        <div className="content_descnewpt_input">
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="prenomCdT"
                                placeholder="Prenom"
                                defaultValue={pt ? eeCon.contact_CdT.prenomCdT : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                                autoComplete="off"
                                onBlur={() => {
                                    setTimeout(() => {
                                        setAutocompleteCDT([]);
                                    }, 100);
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    backgroundColor: "white",
                                    width: "25%",
                                    marginTop: "60px",
                                }}
                            >
                                {autocompleteCDT &&
                                    autocompleteCDT.map((suggest, i) => (
                                        <div key={i} className="suggest" onClick={() => handleSelectedCDT(suggest)}>
                                            {suggest.prenom} {suggest.nom}
                                        </div>
                                    ))}
                            </div>

                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="nomCdT"
                                placeholder="Nom"
                                defaultValue={pt ? eeCon.contact_CdT.nomCdT : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="telCdT"
                                placeholder="Téléphone"
                                defaultValue={pt ? eeCon.contact_CdT.telCdT : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                            <input
                                type="text"
                                onChange={(e) => handle(e)}
                                id="mailCdT"
                                placeholder="Email"
                                defaultValue={pt ? eeCon.contact_CdT.mailCdT : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                        <div className="button_add_do">
                            <button onClick={handleClick3} className="button_add_do">
                                {!modal3 ? <>Ajouter</> : <>Supprimer</>} une entreprise sous-traitante
                            </button>

                            {modal3 && (
                                <div className="new_EST" style={{ marginBottom: "20px" }}>
                                    <div>
                                        <div className="name_newpt">ENTREPRISE SOUS-TRAITANTE</div>
                                        <div className="content_descnewpt_input">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Nom de l'ES : </label>
                                                <input
                                                    type="text"
                                                    onChange={(e, isDirty) => handle(e, isDirty)}
                                                    id="nom_ST_PT"
                                                    placeholder="Nom de l'entreprise"
                                                    defaultValue={
                                                        !deletedModal3Values ? (pt ? eeSt.nom_ST_PT : "") : ""
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                    autoComplete="off"
                                                    onBlur={() => {
                                                        setTimeout(() => {
                                                            setAutocompleteNomES([]);
                                                        }, 100);
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    width: "25%",
                                                    marginTop: "60px",
                                                    marginLeft: "14.75%",
                                                }}
                                            >
                                                {autocompleteNomES &&
                                                    autocompleteNomES.map((suggest, i) => (
                                                        <div
                                                            key={i}
                                                            className="suggest"
                                                            onClick={() => handleSelectedNomES(suggest)}
                                                        >
                                                            {suggest.raison_sociale}
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Site : </label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => handle(e)}
                                                    id="site_ST_PT"
                                                    placeholder="Site"
                                                    defaultValue={
                                                        pt
                                                            ? eeSt.site_ST_PT
                                                                ? eeSt.site_ST_PT
                                                                : data.site_EE_PT
                                                            : data.site_EE_PT
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Atelier : </label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => handle(e)}
                                                    id="zone_travail_ST"
                                                    placeholder="Atelier"
                                                    defaultValue={
                                                        pt
                                                            ? eeSt.zone_travail_ST
                                                                ? eeSt.zone_travail_ST
                                                                : data.zone_travail_EE
                                                            : data.zone_travail_EE
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Ligne : </label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => handle(e)}
                                                    id="ligne_ST"
                                                    placeholder="Ligne"
                                                    defaultValue={
                                                        pt
                                                            ? eeSt.ligne_ST
                                                                ? eeSt.ligne_ST
                                                                : data.ligne_EE
                                                            : data.ligne_EE
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Date de la PV/PDT : </label>
                                                <input
                                                    type="date"
                                                    onChange={(e) => handleDate(e)}
                                                    id="date_vp_ST"
                                                    placeholder="Date"
                                                    defaultValue={
                                                        pt
                                                            ? eeSt.date_vp_ST
                                                                ? eeSt.date_vp_ST
                                                                : data.date_vp_EE
                                                            : data.date_vp_EE
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Début opérations : </label>
                                                <input
                                                    type="date"
                                                    onChange={(e) => handleDate(e)}
                                                    id="date_debut_operations_ST"
                                                    placeholder="Début des opérations"
                                                    defaultValue={
                                                        pt
                                                            ? eeSt.date_fin_operations_ST
                                                                ? eeSt.date_fin_operations_ST
                                                                : data.date_debut_operations_EE
                                                            : data.date_debut_operations_EE
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <label style={{ width: "70%" }}>Fin opérations : </label>
                                                <input
                                                    type="date"
                                                    onChange={(e) => handleDate(e)}
                                                    id="date_fin_operations_ST"
                                                    placeholder="Fin des opérations"
                                                    defaultValue={
                                                        pt
                                                            ? eeSt.date_fin_operations_ST
                                                                ? eeSt.date_fin_operations_ST
                                                                : data.date_fin_operations_EE
                                                            : data.date_fin_operations_EE
                                                    }
                                                    disabled={
                                                        id !== "0" &&
                                                        (pt.status === "clotures" || pt.validation_ec === 1)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="name_newpt">EXÉCUTANT CHARGÉ DES TRAVAUX ES</div>
                                        <div className="content_descnewpt_input">
                                            <input
                                                type="text"
                                                onChange={(e) => handle(e)}
                                                id="prenomCdT_ST"
                                                placeholder="Prénom"
                                                defaultValue={
                                                    !deletedModal3Values
                                                        ? pt
                                                            ? eeConSt.contact_CdT_ST.prenomCdT_ST
                                                            : ""
                                                        : ""
                                                }
                                                disabled={
                                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                        ? true
                                                        : false
                                                }
                                                autoComplete="off"
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setAutocompleteCDTST([]);
                                                    }, 100);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    width: "25%",
                                                    marginTop: "60px",
                                                }}
                                            >
                                                {autocompleteCDTST &&
                                                    autocompleteCDTST.map((suggest, i) => (
                                                        <div
                                                            key={i}
                                                            className="suggest"
                                                            onClick={() => handleSelectedCDTST(suggest)}
                                                        >
                                                            {suggest.prenom} {suggest.nom}
                                                        </div>
                                                    ))}
                                            </div>
                                            <input
                                                type="text"
                                                onChange={(e) => handle(e)}
                                                id="nomCdT_ST"
                                                placeholder="Nom"
                                                defaultValue={
                                                    !deletedModal3Values
                                                        ? pt
                                                            ? eeConSt.contact_CdT_ST.nomCdT_ST
                                                            : ""
                                                        : ""
                                                }
                                                disabled={
                                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <input
                                                type="text"
                                                onChange={(e) => handle(e)}
                                                id="telCdT_ST"
                                                placeholder="Téléphone"
                                                defaultValue={
                                                    !deletedModal3Values
                                                        ? pt
                                                            ? eeConSt.contact_CdT_ST.telCdT_ST
                                                            : ""
                                                        : ""
                                                }
                                                disabled={
                                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <input
                                                type="text"
                                                onChange={(e) => handle(e)}
                                                id="mailCdT_ST"
                                                placeholder="Email"
                                                defaultValue={
                                                    !deletedModal3Values
                                                        ? pt
                                                            ? eeConSt.contact_CdT_ST.mailCdT_ST
                                                            : ""
                                                        : ""
                                                }
                                                disabled={
                                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1)
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="name_newpt">DESCRIPTION DES DIFFÉRENTES PHASES DE L'OPÉRATION A RÉALISER</div>
                        <div className="content_descnewpt_input">
                            <textarea
                                type="text"
                                placeholder="Description"
                                onChange={(e) => handle(e)}
                                id="description_phase_PT"
                                defaultValue={pt ? pt.description_phase_PT : ""}
                                disabled={
                                    id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) ? true : false
                                }
                            />
                        </div>
                    </div>
                    {id !== "0" && (pt.status === "clotures" || pt.validation_ec === 1) || data.nom_PT==null ? null : (
                        <button
                            onClick={submit}
                            className="button_add_do"
                            type="submit"
                            style={{
                                width: "405px",
                                height: "50px",
                                backgroundColor: "#4aabe7",
                                color: "#fff",
                                border: "2px solid #4aabe7",
                                marginBottom: "150px",
                                borderRadius: "5px",
                            }}
                        >
                            Sauvegarder les modifications
                        </button>
                    )}
                </form>
            )}
        </div>
    );
};

export default CardDesc;
