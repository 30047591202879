import React from "react";
import "./Sommaire.css";
import PDFHeader from "../../PDFHeader/PDFHeader";

// Using a class component, everything works without issue
export class Sommaire extends React.PureComponent {
    render() {
        return (
            <div className="PDFValidateurContent">
                <PDFHeader
                    ptId={this.props.pt.id}
                    numPermis={
                        this.props.pt !== undefined
                            ? this.props.pt.numero_PT
                            : ""
                    }
                    prenom={
                        this.props.eu.contact_RdP !== undefined
                            ? this.props.eu.contact_RdP.prenomRdP
                            : ""
                    }
                    nom={
                        this.props.eu.contact_RdP !== undefined
                            ? this.props.eu.contact_RdP.nomRdP
                            : ""
                    }
                />
                <div className="sommairePDF">
                    <h1>PLAN DE TRAVAIL</h1>
                    <h2>SOMMAIRE</h2>
                    <div className="d-flex align-items-center justify-content-evenly">
                        <div>
                            <h3>I - DESCRIPTION</h3>
                            <h3>II - RECOMMANDATIONS</h3>
                            <h3>III - ANALYSE DES RISQUES</h3>
                            <h3>IV - VALIDATION</h3>
                            <h3>V - FIN DE CHANTIER</h3>
                            {/* <h3>VI - DOCUMENTS</h3> */}
                        </div>
                    </div>
                </div>

                <div className="pagebreak"></div>
            </div>
        );
    }
}
