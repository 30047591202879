import React, { useEffect, useState } from "react";
import "./AddUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../../Utils/utils";
import { getUsines } from "../../../../Redux/actions/usine.actions";
import { getUsers, newUser, updateUser } from "../../../../Redux/actions/users.actions";

function UpdateUser({ user, handleCloseUpdate }) {
    const id_hash_volvic =
        "e572fabd53d8db8c9f51b1867374e49e74564ca70bd90188b59eb88eb937733fc7b95a44bad63c6cad565556376415a7902358a7a8331d7afb89bdc0a7646baa";
    const id_hash_badoit =
        "a16daba7c955408177b1e2c9fb168f2c5966b6fc26d0349328cbc4c00e780a916ab7413fe7066505b18df9561e2567d70646ad2a494ca479a62f1abddfbafd40";
    const id_hash_salvetat =
        "4e0149a8e906e9844fd662f20e414e04b729d299632ce78a3d31873b56a36c069e052374249025c138db62605167518fb3bfa888d269754475e93fd0a08f03ce";
    const id_hash_evian =
        "4dce309800d99d34cfd50003c0c7d1082b6bbb62a4c435adb325c8c3e1ca655744209f77750619f658036cd1fd62dd6c6413d3cd8bfd6f17fb35332cdbc0decb";

    const usineReducer = useSelector((state) => state.usineReducer);

    const fetchUsines = async () => {
        await dispatch(getUsines());
    };

    useEffect(() => {
        if (isEmpty(usineReducer[0])) {
            fetchUsines();
        }
    }, [usineReducer]);

    const [prenom, setPrenom] = useState(user.prenom);
    const [nom, setNom] = useState(user.nom);
    const [tel, setTel] = useState(user.tel);
    const [mail, setMail] = useState(user.mail);
    const [usine, setUsine] = useState(user.id_hash_usine);
    const [active, setActive] = useState(user.active);
    const [administrateur, setAdministrateur] = useState(user.admin);
    const [accesPP, setAccesPP] = useState(JSON.parse(user.acces).accesPP);
    const [accesPT, setAccesPT] = useState(JSON.parse(user.acces).accesPT);
    const [permisHauteur, setPermisHauteur] = useState(JSON.parse(user.habilitation).permisHauteur);
    const [permisFouilles, setPermisFouilles] = useState(JSON.parse(user.habilitation).permisFouilles);
    const [permisToiture, setPermisToiture] = useState(JSON.parse(user.habilitation).permisToiture);
    const [permisConfine, setPermisConfine] = useState(JSON.parse(user.habilitation).permisConfine);
    const [permisTension, setPermisTension] = useState(JSON.parse(user.habilitation).permisTension);

    const closeModal = () => {
        handleCloseUpdate();
    };

    const dispatch = useDispatch();

    const handleSubmit = async () => {
        if (prenom === "" || nom === "" || tel === "" || mail === "") {
            alert("Il faut remplir tous les champs de texte");
        } else {
            const habilitation = {
                permisHauteur,
                permisFouilles,
                permisToiture,
                permisConfine,
                permisTension,
            };

            const acces = {
                accesPP,
                accesPT,
            };
            const data = {
                prenom,
                nom,
                tel,
                mail,
                usine,
                active,
                administrateur,
                habilitation,
                acces,
            };
            await dispatch(updateUser(data, user.id));
            await dispatch(getUsers());
            closeModal();
        }
    };

    return (
        <div>
            <div className="AddValidateur_overlay">
                <div className="AddValidateur_wrapper">
                    <div className="AddValidateur_modal" style={{ height: "auto" }}>
                        <div className="AddValidateur_headerR">
                            Modifier utilisateur
                            <button type="button" className="modal-close-button" onClick={closeModal}>
                                X
                            </button>
                        </div>
                        <div className="AddValidateur_content">
                            <input
                                className="AddValidateur_content_input"
                                type="text"
                                onChange={(e) => setPrenom(e.target.value)}
                                placeholder="Prénom"
                                defaultValue={prenom}
                            />
                            <input
                                className="AddValidateur_content_input"
                                type="text"
                                onChange={(e) => setNom(e.target.value)}
                                placeholder="Nom"
                                defaultValue={nom}
                            />
                            <input
                                className="AddValidateur_content_input"
                                type="text"
                                onChange={(e) => setTel(e.target.value)}
                                placeholder="Téléphone"
                                defaultValue={tel}
                            />
                            <input
                                className="AddValidateur_content_input"
                                type="text"
                                onChange={(e) => setMail(e.target.value)}
                                placeholder="Email"
                                defaultValue={mail}
                            />
                            <div style={{ display: "flex", width: "100%" }}>
                                <p>Usine</p>
                                <select
                                    style={{ marginLeft: "2%" }}
                                    onChange={(e) => setUsine(e.target.value)}
                                    defaultValue={usine}
                                >
                                    <option value={id_hash_volvic}>Volvic</option>
                                    <option value={id_hash_badoit}>Badoit</option>
                                    <option value={id_hash_salvetat}>La Salvetat</option>
                                    <option value={id_hash_evian}>Evian</option>
                                </select>
                            </div>
                            <br />

                            <div style={{ width: "100%" }}>
                                <div style={{ marginBottom: "10px" }}>
                                    <h5>STATUT</h5>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setActive(e.target.checked)}
                                        defaultChecked={active}
                                    />
                                    <label>Actif</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setAdministrateur(e.target.checked)}
                                        defaultChecked={administrateur}
                                    />
                                    <label>Administrateur</label>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <h5>ACCES</h5>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setAccesPP(e.target.checked)}
                                        defaultChecked={accesPP}
                                    />
                                    <label>Plan de prévention</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setAccesPT(e.target.checked)}
                                        defaultChecked={accesPT}
                                    />
                                    <label>Permis de travaux</label>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                    <h5>HABILITATION</h5>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setPermisHauteur(e.target.checked)}
                                        defaultChecked={permisHauteur}
                                    />
                                    <label>Permis de travail en hauteur</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setPermisFouilles(e.target.checked)}
                                        defaultChecked={permisFouilles}
                                    />
                                    <label>Permis fouilles et excavations</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setPermisToiture(e.target.checked)}
                                        defaultChecked={permisToiture}
                                    />
                                    <label>Permis de travail en toiture</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setPermisConfine(e.target.checked)}
                                        defaultChecked={permisConfine}
                                    />
                                    <label>Permis de travail espaces confinés</label>
                                    <br />
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setPermisTension(e.target.checked)}
                                        defaultChecked={permisTension}
                                    />
                                    <label>Permis de travail Haute tension</label>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="modal_button_imprimer_validateur" onClick={handleSubmit}>
                            Modifier
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateUser;
